<template>
  <div>
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('MENU.REPORT.FINANCE_REPORT.FINANCE_RESULT') }}
          </h3>
        </div>
      </div>
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-body p-3">
          <table>
            <thead>
              <tr>
                <th rowspan="2" class="text-center">Курсаткичлар номи</th>
                <th rowspan="2" class="text-center">Сатр коди</th>
                <!-- <th colspan="2" class="text-center">
                  Утган йилнинг шу даврида
                </th> -->
                <th colspan="2" class="text-center">Хисобот даврида</th>
              </tr>
              <tr>
                <!-- <th class="text-center">
                  Даромадлар
                </th>
                <th class="text-center">
                  Харажатлар
                </th> -->
                <th class="text-center">Даромадлар</th>
                <th class="text-center">Харажатлар</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Махсулот (Товар, иш ва хизмат) ларни сотишдан соф тушум</td>
                <td>010</td>
                <!-- <td>
                  х
                </td>
                <td>
                  х
                </td> -->
                <td>
                  {{ calculated['010'].income | mask }}
                </td>
                <td>х</td>
              </tr>
              <tr>
                <td>
                  Сотилган махсулот (Товар, иш ва хизмат) ларнинг таннархи
                </td>
                <td>020</td>
                <!-- <td>
                  х
                </td>
                <td></td> -->
                <td>х</td>
                <td>
                  {{ calculated['020'].exp | mask }}
                </td>
              </tr>
              <tr>
                <td>
                  Махсулот (Товар, иш ва хизмат) ларни сотишнинг ялпи фойдаси
                  (зарари) (сатр 010-020)
                </td>
                <td>030</td>
                <!-- <td></td>
                <td></td> -->
                <td>
                  {{
                    calculated['030'].income > 1
                      ? calculated['030'].income
                      : '' | mask
                  }}
                </td>
                <td>
                  {{
                    calculated['030'].exp > 1
                      ? ''
                      : (calculated['030'].exp * -1) | mask
                  }}
                </td>
              </tr>
              <tr>
                <td>Маъмурий харажатлар</td>
                <td>040</td>
                <!-- <td>
                  х
                </td>
                <td></td> -->
                <td>х</td>
                <td>
                  {{ calculated['040'].exp | mask }}
                </td>
              </tr>
              <tr>
                <td>Асосий фаолиятнинг бошка даромадлари</td>
                <td>090</td>
                <!-- <td></td>
                <td>
                  х
                </td> -->
                <td>
                  {{ calculated['090'].income | mask }}
                </td>
                <td>х</td>
              </tr>
              <tr>
                <td>Асосий фаолиятнинг фойдаси (зарари) (сатр 030-040+090)</td>
                <td>100</td>
                <!-- <td></td>
                <td>
                  0
                </td> -->
                <td>
                  {{
                    calculated['100'].income > 1
                      ? calculated['100'].income
                      : 'x' | mask
                  }}
                </td>
                <td>
                  {{
                    calculated['100'].exp > 1
                      ? 'x'
                      : (calculated['100'].exp * -1) | mask
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  Молиявий фаолиятнинг даромадлари, жами (сатр
                  120+130-140+150+160), шу жумладан
                </td>
                <td>110</td>
                <!-- <td></td>
                <td>
                  х
                </td> -->
                <td>
                  {{ calculated['110'].income | mask }}
                </td>
                <td>х</td>
              </tr>
              <tr>
                <td>
                  Молиявий фаолият буйича харажатлар, (сатр 180+190+200+210), шу
                  жумладан
                </td>
                <td>170</td>
                <!-- <td>
                  х
                </td>
                <td>
                  125 122
                </td> -->
                <td>х</td>
                <td>
                  {{ calculated['170'].exp | mask }}
                </td>
              </tr>
              <tr>
                <td>
                  Умумхужалик фаолиятнинг фойдаси (зарари) (сатр 100+110-170)
                </td>
                <td>220</td>
                <!-- <td>
                  4 517 907
                </td>
                <td>
                  0
                </td> -->
                <td>
                  {{
                    calculated['220'].income > 1
                      ? calculated['220'].income
                      : 'x' | mask
                  }}
                </td>
                <td>
                  {{
                    calculated['220'].exp > 1
                      ? 'x'
                      : (calculated['220'].exp * -1) | mask
                  }}
                </td>
              </tr>
              <tr>
                <td>Фавкулотдаги фойда ва зарарлар</td>
                <td>230</td>
                <!-- <td></td>
                <td></td> -->
                <td>
                  {{ calculated['230'].income | mask }}
                </td>
                <td>
                  {{ calculated['230'].exp | mask }}
                </td>
              </tr>
              <tr>
                <td>
                  фойда солигини тулагунга кадар фойда (зарар) (сатр 220+/-230)
                </td>
                <td>240</td>
                <!-- <td>
                  4 517 907
                </td>
                <td>
                  0
                </td> -->
                <td>
                  {{
                    calculated['240'].income > 1
                      ? calculated['240'].income
                      : 'x' | mask
                  }}
                </td>
                <td>
                  {{
                    calculated['240'].exp > 1
                      ? ''
                      : (calculated['240'].exp * -1) | mask
                  }}
                </td>
              </tr>
              <tr>
                <td>фойда солиги</td>
                <td>250</td>
                <!-- <td>
                  х
                </td>
                <td></td> -->
                <td>х</td>
                <td>
                  {{ calculated['250'].exp | mask }}
                </td>
              </tr>
              <tr>
                <td>фойдадан бошка соликлар ва бошка мажбурий туловлар</td>
                <td>260</td>
                <!-- <td>
                  х
                </td>
                <td>
                  648 340
                </td> -->
                <td>х</td>
                <td>
                  {{ calculated['260'].exp | mask }}
                </td>
              </tr>
              <tr>
                <td>
                  Хисобот даврининг соф фойдаси (зарари) (сатр 240-250-260)
                </td>
                <td>270</td>
                <!-- <td>
                  3 869 567
                </td>
                <td>
                  0
                </td> -->
                <td>
                  {{
                    calculated['270'].income > 1
                      ? calculated['270'].income
                      : 'x' | mask
                  }}
                </td>
                <td>
                  {{
                    calculated['270'].exp > 1
                      ? 'x'
                      : (calculated['270'].exp * -1) | mask
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      lists: []
    }
  },
  created() {
    this.$store.dispatch('getMainProfitSubCategorySpecialTotal')
    this.$store.dispatch('getMainExSubCategorySpecialTotal')
  },
  filters: {
    mask(val) {
      if (typeof val !== 'string' && val !== undefined)
        return val.toLocaleString('es-US')
      else return val
    }
  },
  computed: {
    Income() {
      const data = this.$store.state.requests.mainProfitSubCategorySpecialTotal
      const list = []
      data.forEach((element) => {
        list[element.code.charAt(element.code.length - 1)] =
          element.total_income.total
      })
      return list
    },
    Exp() {
      const data = this.$store.state.requests.mainExSubCategorySpecialTotal
      const list = []
      data.forEach((element) => {
        list[element.code.charAt(element.code.length - 1)] =
          element.total_expenditure.total
      })
      return list
    },
    calculated() {
      const datas = {}
      if (this.Income)
        datas['010'] = {
          income: this.Income[1],
          exp: this.Exp[1]
        }
      datas['020'] = {
        income: this.Income[1],
        exp: this.Exp[1]
      }
      datas['030'] = {
        income: this.Income[1] - this.Exp[1],
        exp: this.Income[1] - this.Exp[1]
      }
      datas['040'] = {
        income: this.Income[2],
        exp: this.Exp[2]
      }
      datas['090'] = {
        income: this.Income[2],
        exp: this.Exp[2]
      }
      datas['100'] = {
        income: datas['030'].income - datas['040'].income + datas['090'].income,
        exp: datas['030'].exp - datas['040'].exp + datas['090'].income
      }
      datas['110'] = {
        income: this.Income[3],
        exp: this.Exp[3]
      }
      datas['170'] = {
        income: this.Income[3],
        exp: this.Exp[3]
      }
      datas['220'] = {
        income: datas['110'].income + datas['100'].income - datas['170'].income,
        exp: datas['110'].income + datas['100'].exp - datas['170'].exp
      }
      datas['230'] = {
        income: this.Income[4],
        exp: this.Exp[4]
      }
      datas['240'] = {
        income: datas['220'].income + datas['230'].income - datas['230'].exp,
        exp: datas['220'].exp + datas['230'].income - datas['230'].exp
      }
      datas['250'] = {
        income: this.Income[4],
        exp: this.Exp[4]
      }
      datas['260'] = {
        income: this.Income[4],
        exp: this.Exp[4]
      }
      datas['270'] = {
        income: datas['240'].income - datas['250'].income - datas['260'].income,
        exp: datas['240'].exp - datas['250'].exp - datas['260'].exp
      }
      return datas
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('MENU.REPORT.TITLE') },
        { title: this.$t('MENU.REPORT.FINANCE_REPORT.TITLE') },
        { title: this.$t('MENU.REPORT.FINANCE_REPORT.FINANCE_RESULT') }
      ])
    })
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 5px;
}
</style>
