import { render, staticRenderFns } from "./test2.vue?vue&type=template&id=0acaf658&scoped=true&"
import script from "./test2.vue?vue&type=script&lang=js&"
export * from "./test2.vue?vue&type=script&lang=js&"
import style0 from "./test2.vue?vue&type=style&index=0&id=0acaf658&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0acaf658",
  null
  
)

export default component.exports